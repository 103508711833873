<template>
  <div
    @click.stop
   class="select_container">
    <div
   @click="handleShow"
    :class="['select_val',listShowStatus?'bgBlue':'',listShowStatus?'box_shadow':'']">
    {{selectValue.country + ' ' + selectValue.code}}
    </div>
    <div v-show="listShow == true" class="select_list shadow-lg">
      <ul>
        <li
        class="list_item"
        v-for="item in list"
        v-bind:key='item.id'
        @click="handleItemSelect(item)"
        >
        {{item.country + ' ' + item.code}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {

  data () {
    return {
      listShow: false
    }
  },
  props: {
    selectValue: {
      type: Object,
      default: () => ({
        id: 3,
        country: 'New Zealand',
        code: '+64'
      })
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    selectBlur () {
      this.listShow = false
    },
    handleShow () {
      this.listShow = !this.listShow
    },
    handleItemSelect (e) {
      this.$emit('handleItemSelect', e)
      this.listShow = false
    }
  },
  computed: {
    listShowStatus () {
      return this.listShow
    }
  }
}
</script>

<style scoped>
.select_container{
  cursor: pointer;
  padding-top: 15px;
  height: 60px;
  width: 100%;
  position: relative;
}
.select_val{
  border-radius: 4px;
  border:1px solid #e2e8f0;
  height: 100%;
  display: flex;
  padding-left: .5rem;
  align-items: center;
  transition: .3s;
}
.select_val:hover{
  border-color:rgb(75, 172, 252);
}
.box_shadow{
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
}
.bgBlue{
  border-color:rgb(75, 172, 252);
}
.select_list{
  padding: .5rem 0;
  position: absolute;
  width: 100%;
  background: white;
  z-index: 10;
  border-radius: 2px;
  overflow: hidden;
}
.list_item{
  padding: .25rem 1rem;
  background: #fff;
  transition: .3s;
}
.list_item:hover{
  background: #e6f7ff;
}
</style>
