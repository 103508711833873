<template>
  <div>
    <div class="input-container">
      <input
      v-focus='isAutoFocus'
      :value="input_value"
      @input="handleUpdateValue"
      class="inputStyle" :type="i_type" :id='id_type'
      autocomplete="on"
      required>
      <label class="input-label" :for="id_type"><span class="placeHolder-class">{{placeHolder}}</span></label>
      <div v-if="isPwd"
      @click="toggleType"
      >
        <transition name="slide-fade">
        <img
        v-if='i_type==="password"'
        class="showEye" src="../assets/icon/showEye.png" alt="">
        <img v-else
        class="showEye" src="../assets/icon/hideEye.png" alt="">
      </transition>
      </div>
    </div>
    <div>
      <transition-group name="list">
        <div class="error-box" v-for="item in errorMsgList" :key="item">
        <img class="error-img" src="../assets/icon/error.png" alt="">
        <span class="error-msg">{{item}}</span>
        </div>
      </transition-group>
      </div>
  </div>
</template>

<script>

export default {

  props: {
    placeHolder: {
      type: String,
      default: 'PlaceHolder'
    },
    i_type: {
      type: String,
      default: 'text'
    },
    regObj: {
      type: Array,
      default: () => []
    },
    id_type: {
      type: String,
      default: 'input'
    },
    input_value: {
      type: String,
      default: ''
    },
    customerProp: {
      type: String,
      default: ''
    },
    customerTriggle: {
      type: Boolean,
      default: false
    },
    isPwd: {
      type: Boolean,
      default: false
    },
    isProve: {
      type: Boolean,
      default: false
    },
    isAutoFocus: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showError: false,
      showTick: false,
      errorMsgList: [],
      showPWd: false
    }
  },
  methods: {
    toggleType () {
      (this.i_type === 'password') ? this.$emit('update:i_type', 'text') : this.$emit('update:i_type', 'password')
    },
    handleUpdateValue (e) {
      this.$emit('handleUpdateValue', e.target.value)
      this.errorMsgList = this.regObj.filter(item => !item.reg.test(e.target.value)).map(item => item.msg)
    },
    checkIsEmpty () {
      return this.input_value === ''
    },
    comparePwd () {
      return this.input_value === this.customerProp
    }
  },
  computed: {
  },
  watch: {
    input_value (newValue, oldValue) {
      if (this.customerTriggle && this.customerProp !== '') {
        const diffError = 'Two input password must be consistent'
        const list = this.errorMsgList
        if (newValue !== this.customerProp) {
          if (!list.includes(diffError)) {
            list.push(diffError)
          }
        } else {
          list.splice(list.findIndex((item) => item === diffError), 1)
        }
      }
      if (newValue !== '' && this.errorMsgList.length === 0) {
        this.$emit('update:isProve', true)
      } else {
        this.$emit('update:isProve', false)
      }
    },
    customerProp (newValue, oldValue) {
      if (newValue === this.input_value) {
        const diffError = 'Two input password must be consistent'
        const list = this.errorMsgList
        if (list.includes(diffError)) {
          list.splice(list.findIndex((item) => item === diffError), 1)
        }
        if (this.errorMsgList.length === 0 && newValue !== '') {
          this.$emit('update:isProve', true)
        }
      }
    }
  }
}
</script>

<style scoped>
.input-container{
  width: 100%;
  height: 60px;
  position: relative;
  border-radius: 2px;
  padding-top: 1rem;
  overflow: hidden;
}
.inputStyle{
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 16px;
  outline: none;
  height: 100%;
  width: 100%;
  padding-left: 3%;
}
.input-label{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.input-label::after{
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0px;
  border-bottom: 1px solid #163257;
  transform: translateX(-100%);
  transition:  all 0.2s ease;
}
.inputStyle:focus + .input-label::after
,.inputStyle:valid + .input-label::after
{
  transform: translateX(0);
}
.placeHolder-class{
  position: absolute;
  bottom: calc( 20% );
  left: 3%;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 400;
  transition: all 0.3s ease ;
  background: #FFF;
}
.inputStyle:valid + .input-label > .placeHolder-class,
.inputStyle:focus + .input-label > .placeHolder-class{
  transform: translateY(-100%);
  color: #163257;
  font-weight: 500;
}
.error-box{
  margin-top: 5px;
  padding-left: 2%;
  display: flex;
  align-items: center;
}
.error-img{
  width: 12px;
  height: 12px;
}
.error-msg{
  padding-left: 5px;
  font-size: 12px;
  color: #f56c6c;
}
.right-img{
  position: absolute;
  right: 3%;
  top: calc(50%);
  width: 34px;
  height: 22px;
  cursor: pointer;
}
.showEye{
  position: absolute;
  right: 3%;
  top: calc(50%);
  width: 26px;
  height: 24px;
  cursor: pointer;
}
.list-enter-active, .list-leave-active {
  transition: all 0.1s;
}
.list-enter, .list-leave-to
/* .list-leave-active for below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.slide-fade-enter-active,.slide-fade-leave-active{
  transition: all 0.3s ease;
  opacity: 1;
}

.slide-fade-enter, .slide-fade-leave-to
 {
  opacity: 0;
}
</style>
